.error_messages {
  &.error {
    li {
      margin: 10px 0;
    }
  }
}

.error {
  color: $color-red;
}

.top-header,
.account {
  .site-header {
    .site-utils {
      &__item--email {
        .site-utils__dropdown {
          .site-email-signup {
            &__form {
              .sign-in-component {
                &__birthday-program {
                  .day-of-birth {
                    &__collection-field {
                      a {
                        &.select-day,
                        &.select-month,
                        &.select-year {
                          width: 30%;
                        }
                      }
                    }
                  }
                }
                &__birthday-program-copy {
                  display: block;
                }
              }
            }
            &__submit {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.site-utils {
  &__dropdown {
    .account-links {
      &__favorites {
        display: none;
      }
    }
  }
  .registration {
    &__container {
      .sign-in-component {
        &__label {
          cursor: text;
        }
      }
    }
  }
}

.customer-service-section {
  .cs-contact-form {
    &__radio {
      display: inline-block;
      margin-#{$rdirection}: 20px;
    }
  }
  h2,
  h3 {
    font-family: $brandon-text;
    @include breakpoint($medium-up) {
      font-family: $brandon-text-bold;
    }
  }
  #customer_service_chat {
    display: block;
    a {
      bottom: -15px;
      .footer-links {
        &__title {
          font-size: 14px;
          color: $color-red !important;
        }
      }
    }
  }
  @include breakpoint($medium-up) {
    .customer-service-landing-block:nth-child(1),
    .customer-service-landing-block:nth-child(2) {
      border-bottom: 1px solid $color-gray-alt;
    }
    .customer-service-landing-block:nth-child(3) {
      border-right: 1px solid $color-gray-alt;
    }
  }
}

.customer-service-quick-info {
  &__list-item {
    &--email {
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
    &--phone {
      @include breakpoint($medium-up) {
        width: 40%;
      }
    }
  }
}

.responsive-tabs {
  &--powerreviews {
    #pr-reviewdisplay {
      .p-w-r {
        .pr-qa-display {
          &.pr-qa-display-tablet,
          &.pr-qa-display-desktop {
            .pr-qa-display-thankyou-headline {
              float: #{$ldirection};
            }
          }
        }
        .pr-review-display {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '是';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '否';
              }
            }
          }
        }
        .pr-review-snapshot-tags {
          display: none;
        }
        .pr-review-snapshot {
          .pr-ratings-histogram {
            .pr-histogram-stars {
              height: 27px;
              margin-bottom: 23px;
              display: table !important;
            }
          }
          &.pr-snapshot-mobile {
            .pr-review-snapshot-block {
              width: 100%;
            }
          }
          .pr-snippet-read-and-write {
            bottom: 40px;
            @include breakpoint($medium-up) {
              bottom: 20px;
            }
          }
        }
      }
    }
  }
}

select {
  &::-ms-expand {
    display: none;
  }
}

.optional-info {
  &__birthday-text {
    float: #{$ldirection};
  }
}

.spp-product-layout {
  .spp-product-layout {
    &__content {
      .spp-product-layout {
        &__content-item-heading {
          @include breakpoint($medium-up) {
            font-size: 50px;
          }
        }
      }
    }
  }
}

.store-locator {
  &__links-container {
    .icon-plane {
      display: none;
    }
  }
  .responsive-tabs {
    &--store-locator {
      li {
        width: 100%;
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .store-locator {
    &__tooltip {
      .store-locator {
        &__tooltip-name {
          font-size: 14px;
        }
      }
    }
  }
}

.section-products {
  .mpp-header-content-block {
    .custom-text {
      p {
        @include breakpoint($medium-up) {
          line-height: 1.1 !important;
        }
      }
    }
  }
}

.section-elizabeth-olsen,
.section-yara-shahidi,
.section-ni-ni {
  .product-collection-product {
    &__product-name {
      h2 {
        font-size: 15px;
        margin-bottom: 20px;
      }
    }
  }
  .product-image-qs_cta {
    @include brandon-text-bold;
  }
  .callout {
    border-bottom: none;
    padding-bottom: 10px;
  }
}

body {
  .site-footer {
    &__main {
      .site-footer {
        &__column {
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}

.site-footer {
  &__column {
    .site-footer-social-links {
      float: #{$ldirection};
      @include breakpoint($medium-up) {
        width: 30%;
      }
    }
  }
  .site-footer_v2 {
    .site-footer {
      &__column {
        &:nth-child(4),
        &:nth-child(5) {
          .device-mobile & {
            display: block;
          }
        }
        &:nth-child(6) {
          .device-mobile & {
            display: none;
          }
        }
        .footer-header {
          font-family: $brandon-text-bold;
        }
      }
    }
    &.site-footer {
      &__main {
        font-family: $brandon-text-medium;
      }
    }
  }
  .footer-column {
    a {
      &.footer-tc {
        font-family: $brandon-text;
        margin-#{$rdirection}: 15%;
        display: inline-block;
      }
    }
  }
  &__trustmark-image {
    img {
      margin-top: 10px;
    }
  }
}

.site-content {
  .content-block {
    &__line {
      &--header {
        .style--bare {
          line-height: 1.3;
        }
      }
    }
  }
}

.homepage_evergreen_services_body {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  .link {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    @include breakpoint($medium-up) {
      bottom: 40px;
    }
  }
}

.search-results__header {
  .search-form {
    &__results-count {
      line-height: 35px;
      #{$rdirection}: 181px;
    }
    input[type=submit].search-form__submit {
      margin-#{$ldirection}: 0;
    }
  }
}

.product-brief {
  &__product-info-wrapper {
    .product-brief {
      &__bottom-mobile {
        .product-brief {
          &__add-to-bag {
            float: #{$rdirection};
            @include breakpoint($medium-up) {
              float: none;
            }
          }
        }
      }
    }
  }
}

.hero-content-block {
  &.content-block {
    &--hero {
      @include breakpoint($medium-down) {
        height: auto;
      }
      .position {
        &--bare,
        &-image {
          .content-block {
            &__content-wrapper {
              @include breakpoint($medium-down) {
                position: static;
              }
            }
          }
        }
      }
    }
  }
}

.section-kai {
  .hero-content-block {
    &.content-block {
      &--hero {
        .position {
          &--bare,
          &-image {
            .content-block {
              &__content-wrapper {
                @include breakpoint($medium-down) {
                  position: absolute;
                }
              }
            }
          }
        }
      }
    }
  }
}

.lp_buttons_area {
  .lp_submit_button,
  .lp_confirm_button,
  .lp_close_survey_button {
    &:hover {
      background: $color-black !important;
    }
  }
}

.lp_pagination-area {
  .lp_buttons_area {
    .lp_next_button {
      background: $color-black !important;
    }
  }
}

.product-foundation-vto {
  &__overlay-title {
    font-size: 4em;
  }
  &__container {
    .product-foundation-vto {
      &__mobile-ctrl {
        ul.shade-list-vto {
          height: 76px;
          .swatches--single {
            a.swatch {
              background-color: $color-white;
            }
          }
          &.all-shades {
            span.vto-text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &__overlay {
    .vto-ios-browser-error {
      z-index: 10000;
    }
  }
}

.change-password {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        #{$ldirection}: 360px;
        &-reset {
          width: 170px;
          #{$ldirection}: 220px;
        }
        &-confirm {
          width: 170px;
          #{$ldirection}: 260px;
        }
        &:before {
          #{$ldirection}: -6%;
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
  }
}

body.lp {
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0);
}

#custom-container #lp-dashboard-btn-container {
  position: fixed;
  top: 2px;
  #{$rdirection}: 2px;
  color: white;
  font-size: 1.2em;
  @include swap_direction(padding, 5px 0px 9px);
  margin-#{$rdirection}: 20px;
}

#lp-dashboard-btn-container div {
  border-bottom: 2px solid white;
  float: $ldirection;
  margin-#{$rdirection}: 12px;
}

#lp-dashboard-btn-container a {
  color: white;
}

#custom-container #user-input-container {
  width: 477px;
  position: absolute;
  top: 100px;
  border: 1px solid lightgray;
}

#custom-container {
  width: 850;
  overflow: hidden;
  font-size: 0.9em;
}

#chatLines {
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}

#chat_window,
.chat-window-container {
  overflow: hidden;
}

#chatLines .no-icon-line {
  @include swap_direction(padding, 3px 6px);
  display: block;
}

#chatLines .icon {
  width: 30px;
  height: 33px;
  border: 1px solid lightgray;
  float: $ldirection;
  @include swap_direction(margin, 2px 10px 0 12px);
}

.rich-line-content {
  width: 100%;
  clear: both;
  @include swap_direction(padding, 12px);
}

.rich-line-content .icon {
  float: $ldirection;
  clear: $ldirection;
}

.rich-line-content .txt-line {
  float: $ldirection;
  width: 382px;
}

.rich-line-content .line-by {
  font-weight: bold;
}

.rich-line-content .icon-agent,
.rich-line-content .icon-system {
  background-color: #000000;
}

.beauty-consultant-profile .consultant-location,
.beauty-consultant-profile .consultant-msg,
.beauty-consultant-profile .consultant-products {
  visibility: hidden;
}

.agent-typing-msg {
  clear: both;
  @include swap_direction(padding, 3px 6px);
}
//.logs { display:none; }
//#logs div { font-size:0.7em; }

.chat-window-container {
  position: relative;
  float: $ldirection;
  width: 475px;
  height: 475px;
  /* border:1px solid lightgray;
    border-top:0px; */
  overflow-y: scroll;
  overflow-x: hidden;
}

#custom-container .text-input-container {
  position: absolute;
  top: 580px;
  border-#{$rdirection}: 1px solid lightgray;
  height: 167px;
  width: 477px;
  padding-top: 14px;
}

#custom-container .input-field-container {
  /* width: 373px; */
  float: $ldirection;
  height: 30px;
  @include swap_direction(padding, 4px 10px);
}

#custom-container input.chat-input-field {
  width: 348px;
  height: 35px;
  font-size: 1em;
}
/* */

#emailTranscript,
#closeChat {
  cursor: pointer;
  font-family: arial;
  font-size: 14px;
  letter-spacing: 0.3em;
}
/* */

#custom-container .btn {
  cursor: pointer;
}
/*
#custom-container .btn-send {
    width:80px;
    height:35px;
    @include swap_direction (margin,4px);
}
*/
#custom-container .send-btn {
  border: 1px solid gray;
  cursor: pointer;
  display: block;
  float: $rdirection;
  height: 30px;
  @include swap_direction(margin, 7px 20px 7px 10px);
  width: 72px;
}

#custom-container .btn-upload {
  background: none;
  border: 0 none;
  @include swap_direction(margin, 0 auto);
  width: 100%;
}

#custom-container .btn-upload img {
  @include swap_direction(margin, 0 auto);
  display: block;
}

#custom-container .upload-silhoette {
  width: 185px;
  height: 190px;
  @include swap_direction(margin, 0 auto);
  margin-top: 5px;
}
/* #custom-container .upload-silhoette img {
    height:202px;
} */
#custom-container .brand-topband {
  @include swap_direction(padding, 15px);
  background-color: #000000;
}

#custom-container .right-col-top .top-right-photo-upload-container .login-text {
  @include swap_direction(margin, 0 auto);
  @include swap_direction(padding, 3px);
  width: 240px;
}

#vba-window-container #vba-logo {
  font-size: 1.5em;
  color: lightgray;
}

#vba-logo {
  height: 40px;
  width: 212px;
  @include swap_direction(margin, 30px 0px 0px 20px);
}
/* layout */

#custom-container .right-col-top .top-right-video-container {
}

#custom-container .right-col-top .top-right-photo-upload-container {
}

#custom-container .right-col-top .top-right-photo-upload-container .login-text {
  font-size: 0.8em;
  @include swap_direction(padding, 0 12px);
  text-align: center;
}

#custom-container .right-col-top {
  position: absolute;
  top: 60px;
  #{$ldirection}: 478px;
  border: 1px solid lightgray;
  border-#{$ldirection}: 0px;
  float: $rdirection;
  height: 325px;
  width: 360px;
  overflow: hidden;
}

#custom-container .right-col-bottom {
  position: absolute;
  #{$ldirection}: 478px;
  top: 385px;
  float: $rdirection;
  clear: both;
  width: 360px;
  height: 325px; /* height:362px; */
  border: 1px solid lightgray;
  border-#{$ldirection}: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

#chatContainer {
  position: relative;
  margin-top: 3px;
  display: none;
  position: absolute;
  height: 100%;
  overflow: auto;
  width: 475px;
}
/* photo upload */

#custom-container .section-heading-photo {
  @include swap_direction(margin, 12px 12px 12px 45px);
  letter-spacing: 0.1em;
}

#custom-container .section-heading-consultant {
  @include swap_direction(margin, 12px 12px 15px 28px);
  letter-spacing: 0.1em;
  overflow: hidden;
  width: 313px;
}

#custom-container .section-heading-consultant img {
  display: block;
  margin-#{$ldirection}: -13px;
}

#custom-container .icon-consultant,
#custom-container .icon-chat-visitor {
  /* width:74px; height:86px; */
}

#custom-container .chat-face-icon {
  float: $ldirection;
  @include swap_direction(margin, 12px 24px);
}

#custom-container .consultant-name {
  float: $ldirection;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding-#{$ldirection}: 0px;
  padding-top: 40px;
  width: 50%;
}

#custom-container .consultant-location {
  float: $ldirection;
  width: 50%;
  @include swap_direction(padding, 6px 0px);
}

#custom-container .consultant-msg,
#custom-container .consultant-location,
#custom-container .consultant-products {
  @include swap_direction(padding, 4px 15px);
  width: 100%;
  clear: both;
  line-height: 15px;
  font-size: 12px;
}

#custom-container .beauty-consultant-profile .section-title {
  font-weight: bold;
  text-transform: uppercase;
}

#photo-upload-query .next-row {
  clear: both;
}

#photo-upload-query .choose-photo-btn {
}

#photo-upload-query .file-chosen-state span {
  height: 12px;
  width: 175px;
  display: block;
  text-overflow: ellipsis;
}

#photo-upload-query .msg-instructions .profile-pic-upload__help p {
  margin-bottom: 6px;
}

#photo-upload-query .msg-instructions .profile-pic-upload__help ul {
  line-height: 24px;
  list-style: outside none disc;
  @include swap_direction(margin, 0 0 0 20px);
}

#photo-upload-query .msg-instructions {
  @include swap_direction(margin, 0 auto);
  font-size: 0.9em;
  @include swap_direction(padding, 12px 0 14px 0);
  width: 400px;
  text-align: $ldirection;
}

#photo-upload-query .btn-instructions {
  @include swap_direction(margin, 0 auto);
  font-size: 0.9em;
  @include swap_direction(padding, 12px 0 14px 0);
  width: 400px;
  text-align: center;
  margin-top: 24px;
}

#photo-upload-query .msg-header {
  text-align: center;
  margin-top: 24px;
  font-size: 1.2em;
}

#photo-upload-query .photo-upload-row {
  width: 400px;
}

#photo-upload-query #choose-file-btn {
  float: $ldirection;
  @include swap_direction(padding, 8px);
  text-align: center;
  width: 140px;
  @include swap_direction(margin, 0px);
  cursor: pointer;
  background-color: #ff4661;
}

#photo-upload-query #file-save-btn {
  padding-top: 7px;
  width: 80px;
  @include swap_direction(margin, 32px auto);
}

#photo-upload-query #file-chosen-state {
  float: $ldirection;
  @include swap_direction(padding, 6px 0);
  margin-#{$ldirection}: 24px;
  text-align: center;
  border-bottom: 1px solid #ff4661;
}

#photo-upload-query #file-chosen-state span {
  color: #ff4661;
}

.vba-x-close {
  height: 20px;
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
  width: 20px;
  cursor: pointer;
}

body.lp #cboxOverlay {
  background: none repeat scroll 0 0 gray;
  opacity: 0.92;
  height: 100%;
  position: fixed;
  width: 100%;
}

body.lp #cboxOverlay {
  #{$ldirection}: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999;
  display: none;
}
/* */
#beauty-consultant-panel-integrated {
  display: none;
}

#beauty-consultant-panel-integrated .btn-arrowRight {
  float: $ldirection;
  height: 32px;
  width: 18px;
  @include swap_direction(margin, 72px 12px 0 0);
  cursor: pointer;
  opacity: 0.5;
}
/* */

#product-recommendations-panel {
  display: block;
}

#product-recommendations-panel .section-heading-lbl {
  @include swap_direction(margin, 12px);
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-bottom: 15px;
}

#product-recommendations-panel .product-sku-panel {
  @include swap_direction(padding, 25px);
}

#product-recommendations-panel .carousel-left-arrow img,
#product-recommendations-panel .carousel-left-arrow-off img {
  margin-#{$ldirection}: -16px;
}

#product-recommendations-panel .carousel-right-arrow img,
#product-recommendations-panel .carousel-right-arrow-off img {
  margin-#{$ldirection}: 12px;
}

#product-recommendations-panel .carousel-left-arrow,
#product-recommendations-panel .carousel-right-arrow {
  height: 32px;
  @include swap_direction(margin, 45px 15px);
  width: 18px;
}

#product-recommendations-panel .carousel-left-arrow-off,
#product-recommendations-panel .carousel-right-arrow-off {
  opacity: 0.5;
  cursor: none;
  height: 32px;
  @include swap_direction(margin, 45px 15px);
  width: 18px;
  cursor: default;
}

#product-recommendations-panel .carousel-left-arrow-off {
  float: $ldirection;
}

#product-recommendations-panel .carousel-right-arrow-off {
  float: $rdirection;
}

#product-recommendations-panel .carousel-left-arrow {
  float: $ldirection;
  cursor: pointer;
}

#product-recommendations-panel .carousel-right-arrow {
  float: $rdirection;
  cursor: pointer;
}

#product-recommendations-panel .carousel-content-container {
  width: 213px;
  height: 200px;
  overflow: hidden;
  float: $ldirection;
}

#product-recommendations-panel .carousel-content-container .carousel-panel-mask {
  min-width: 2000px;
  #{$ldirection}: 0;
  position: relative;
}

#product-recommendations-panel .carousel-content-container .carousel-content-panel {
  width: 213px;
  height: 200px;
  float: $ldirection;
}

#product-recommendations-panel .carousel-content-container .img-col {
  width: 60px;
  float: $ldirection;
  margin-#{$rdirection}: 12px;
}

#product-recommendations-panel .carousel-content-container .img-col .mock-prod-img {
  width: 60px;
  height: 90px;
}

#product-recommendations-panel .carousel-content-container .detail-col {
  float: $ldirection;
  width: 132px;
}

#product-recommendations-panel .carousel-content-container .prod-title {
  font-size: 1.1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
}

#product-recommendations-panel .carousel-content-container .prod-subtitle {
  font-size: 1em;
  overflow: hidden;
  width: 175px;
  display: none;
}

#product-recommendations-panel .carousel-content-container .prod-units {
  @include swap_direction(padding, 2px 0px);
  overflow: hidden;
  width: 175px;
}

#product-recommendations-panel .carousel-content-container .shade-container {
  @include swap_direction(padding, 4px 0px);
  overflow: hidden;
  width: 175px;
  height: 22px;
}

#product-recommendations-panel .carousel-content-container .shade-container .shade-shim {
  overflow: hidden;
  float: $ldirection;
  border-radius: 18px;
  -moz-border-radius: 18px;
  width: 18px;
  height: 18px;
}

#product-recommendations-panel .carousel-content-container .shade-container .shade-name {
  overflow: hidden;
  float: $ldirection;
  width: 150px;
  margin-#{$ldirection}: 2px;
}

#product-recommendations-panel .carousel-content-container .price-info {
  font-weight: bold;
  overflow: hidden;
  margin-top: 6px;
  width: 175px;
}

#product-recommendations-panel .carousel-content-container .add-to-bag-btn-container {
  @include swap_direction(margin, 20px 0px);
}

#product-recommendations-panel .carousel-content-container .btn-addToBag {
  height: 33px;
  width: 132px;
  cursor: pointer;
}

#product-recommendations-panel .carousel-content-container .msg-addToBag {
  margin-#{$ldirection}: -9px;
  margin-top: 3px;
  display: none;
}

#product-recommendations-panel .section-heading-consultant img {
  display: block;
  margin-#{$ldirection}: -8px;
}
/* landing and post chat target pages */
.beauty-consultations {
  text-align: center;
  @include swap_direction(padding, 25px);
  text-align: center;
  @include swap_direction(margin, 0px auto);
  width: 750px;
}

.beauty-consultations .conditional-has-added-to-bag {
  display: none;
  margin-top: 24px;
  margin-bottom: 12px;
}

.beauty-consultations .beauty-consultations-headline {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bold;
}

.thankyou-page-tiles {
  @include swap_direction(margin, 0 auto);
  width: 945px;
  margin-bottom: 20px;
}

.thankyou-page-tiles img {
  padding-#{$rdirection}: 3px;
}

.thankyou .beauty-consultations-headline {
  @include swap_direction(margin, 36px);
}

.beauty-consultations .beauty-consultations-large-msg {
  font-size: 2em;
  width: 720px;
  @include swap_direction(margin, 20px auto);
  text-transform: uppercase;
  font-weight: bold;
}

.beauty-consultations-large-msg .quotes {
  font-size: 24px;
  font-style: italic;
  line-height: 28px;
}

.beauty-consultations-large-msg .msg-content {
  width: 500px;
  @include swap_direction(margin, 0 auto);
}

.beauty-consultations-large-msg .msg-content .h6 {
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: bold;
}

.beauty-consultations-large-msg .msg-content p {
  font-size: 14px;
  margin-bottom: 14px;
}

.vba-tgt-pg-divider {
  border-bottom: 1px solid lightgray;
  width: 500px;
  @include swap_direction(margin, 0 auto);
}

.beauty-consultations-lbl {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.beauty-consultations-placement-msg {
  font-size: 1.4em;
}

.beauty-consultations .btn-choices-row {
  width: 345px;
  height: 45px;
  @include swap_direction(margin, 0px auto);
}

.beauty-consultations .btn-choices-row .or-space {
  @include swap_direction(padding, 10px);
}

.beauty-consultations .btn-choices-row div {
  float: $ldirection;
}

.beauty-consultations #order-question-btn,
.beauty-consultations #save-to-profile-btn,
.beauty-consultations #write-review-btn {
  @include swap_direction(margin, 0 auto);
  width: 150px;
  background-color: #0d091a;
  color: white;
}

.beauty-consultations #start-chat-btn {
  border-radius: 5px;
  @include swap_direction(padding, 10px);
  width: 150px;
}

.beauty-consultations #save-to-profile-btn {
  border-radius: 5px;
  @include swap_direction(padding, 10px);
  width: 150px;
}

.beauty-consultations .beauty-consultations-instructions {
  font-size: 0.9em;
}

.beauty-consultations-instructions a {
  text-decoration: underline;
  color: black;
}
/* thank you page b */
.beauty-consultations-instructions button.create-account {
  border: 1px solid #ff4661;
  color: #ff4661;
  @include swap_direction(padding, 3px 6px);
  height: 36px;
  background-color: white;
}
/* */
.vba-btn {
  color: white;
  @include swap_direction(margin, 0 auto);
  cursor: pointer;
}

.vba-txt-btn {
  text-align: center;
  width: 175px;
  background-color: #ff4661;
  color: white;
  height: 32px;
  @include swap_direction(margin, 10px auto);
  padding-top: 10px;
  cursor: pointer;
}

#photo-upload-query .file-save-btn-row {
  float: $ldirection;
  clear: both;
  width: 80px;
  position: relative;
}

#photo-upload-query .vba-txt-btn {
  background-color: black;
}

@mixin brandon-text-bold {
  font-weight: 550;
  font-size: 16px;
  font-family: Brandon Text Bold, Microsoft Yahei UI, PingFang, sans-serif;
}

@mixin brandon-text {
  font-size: 15px;
  font-family: Brandon Text, Microsoft Jhenghei, PingFang, sans-serif;
}

.mpp-container {
  &__content {
    .product-brief {
      &__header {
        &.product-brief {
          &__sub-line {
            @include brandon-text-bold;
          }
        }
      }
      .product-brief {
        &__short-desc {
          @include brandon-text;
        }
      }
    }
  }
}

.spp-product-layout {
  .product-full {
    .product-full {
      &__sub-line {
        @include brandon-text-bold;
      }
      &__short-desc {
        @include brandon-text;
      }
    }
  }
}

.site-email-sms-thank-you {
  &__header {
    h1 {
      font-family: $brandon-text-bold;
    }
  }
}

.site-email-sms-signup {
  &__header {
    h1,
    .at-h2 {
      font-family: $brandon-text-bold;
    }
  }
  &__error {
    font-family: $brandon-text-bold;
  }
}

.customer-service-contact-form {
  &__category {
    font-family: $brandon-text-bold;
  }
}

.customer-service-header {
  &__title {
    font-family: $brandon-text-bold;
  }
}

.customer-service-landing-block {
  a {
    font-family: $brandon-text-bold;
  }
}

.customer-service-menu {
  .menu {
    &__link--lvl-1 {
      font-family: $brandon-text-bold;
    }
  }
}

.customer-service-quick-info {
  &__link {
    font-family: $brandon-text-bold;
  }
}

.spp-mobile-page {
  .select_shade_cta {
    font-family: $brandon-text-bold;
  }
}

.account-page {
  &__content {
    .section-header {
      &__link {
        @include breakpoint($landscape-up) {
          bottom: 20px;
        }
      }
    }
  }
  .account-profile {
    &__email {
      word-break: break-word;
    }
  }
}

.account-qr_section {
  display: flex;
  justify-content: center;
  @include breakpoint($landscape-up) {
    justify-content: normal;
  }
}

.address-book {
  .address-item {
    height: 380px;
    word-break: break-word;
  }
  &__controls {
    &--make-default {
      padding-top: 10px;
      clear: both;
      display: block;
    }
    &--default {
      margin-top: 10px;
    }
  }
  &__link {
    margin-top: 30px;
  }
}

.site-email-sms-signup {
  &__birthday {
    text-align: $left;
  }
}

.account {
  &__new-account {
    input[type='text'] {
      margin-bottom: 20px;
    }
  }
  button {
    &.upload-file {
      &__imposter {
        &.button {
          &--secondary {
            &:hover {
              background: $color-pink !important;
            }
          }
        }
      }
    }
  }
  .upload-file {
    #form {
      &--profile {
        &--field {
          &--UPLOADED_FILE {
            width: 83px;
          }
        }
      }
    }
  }
  &.signin {
    .sign-birthday-info {
      &.optional-info {
        .optional-info {
          &__birthday-month {
            width: 33%;
          }
        }
      }
    }
  }
  .optional-info {
    &__item--gender {
      margin-#{$ldirection}: 10px;
      padding: 10px;
    }
  }
  .address-form {
    &__item {
      .address-label {
        display: none;
      }
      &.default-shipping-check {
        .address-label {
          display: block;
        }
      }
    }
  }
}

input[type='file'] {
  cursor: pointer;
  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}

#order_detail {
  .order-products {
    .cart-item {
      &__qty {
        @include breakpoint($small-down) {
          float: none;
        }
      }
    }
  }
}

.address-form {
  &__item {
    @include breakpoint($medium-up) {
      &.half-width {
        width: 49%;
      }
      &.fleft {
        float: $ldirection;
      }
      &.fright {
        float: $rdirection;
      }
    }
  }
}

.device-mobile {
  .account-breadcrumb-wrapper {
    display: none;
  }
}

@mixin progressbar-color {
  background: $color-black;
  color: $color-black;
  border: 1px solid $color-black;
}

@mixin progressbar-font {
  font-weight: bold;
  color: $color-black;
}

@mixin progressbar-before {
  @include swap_direction(margin, 0 auto 5px auto);
  position: relative;
  content: counter(step);
  counter-increment: step;
  width: 17px;
  line-height: 1.4;
  display: block;
  color: $color-gray-dark;
  border: 1px solid $color-gray-dark;
  background: $color-gray-dark;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  z-index: 2;
}

.checkout {
  &__content {
    width: auto;
    @include breakpoint($medium-up) {
      width: 100%;
    }
    @include breakpoint($medium-up) {
      width: 62.98828%;
    }
    .hide-checkout-buttons,
    .guarantee-panel {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .guarantee-panel {
      margin-top: 1em;
      padding: 1em;
      background: $color-gray-background;
      &__title {
        margin: 0;
      }
    }
    .order-summary-panel {
      margin-bottom: 10px;
    }
    .shipping-panel {
      .pickup-store {
        display: inline-block;
        &--img {
          width: 50px;
          height: 50px;
        }
      }
    }
    .viewcart & {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 62.98828%;
        padding: 0 20px;
      }
    }
    #gwp-message {
      color: $color-red;
    }
    .shipping-address,
    .billing {
      .half-width {
        width: 49%;
      }
      .fleft {
        float: $ldirection;
      }
      .fright {
        float: $rdirection;
      }
    }
  }
  &.empty_cart {
    .viewcart-panel {
      margin-bottom: 20px;
    }
  }
  &__sidebar {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 37.01172%;
    }
    .viewcart & {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 37.01172%;
      }
    }
    .product-list {
      min-height: auto;
    }
    .links-panel,
    .past-purchases-panel {
      padding: 16px 20px;
      background: $color-gray-background;
    }
    .past-purchases-panel {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .purchase {
      &__content {
        .product {
          @include swap_direction(padding, 20px 0 0 0);
          margin: 0 8px;
          .product-img {
            max-width: 80px;
            width: 35%;
            float: #{$ldirection};
          }
          .details {
            margin-top: 0;
            width: 65%;
            display: inline-block;
            float: #{$rdirection};
            .product-name-link {
              color: $color-black;
              text-decoration: none;
            }
          }
          .shades-list {
            margin-top: 8px;
            .skus {
              margin-top: 0;
              .swatch {
                display: inline-block;
              }
              .shade {
                @include swap_direction(margin, 2px 0 0 10px);
                display: inline-block;
                vertical-align: top;
              }
            }
          }
          .price {
            margin-bottom: 8px;
          }
        }
        .prev-next {
          margin-top: 10px;
          .fav-prev {
            float: #{$ldirection};
          }
          .fav-next {
            float: #{$rdirection};
          }
        }
      }
    }
    .need-help-panel {
      margin: 10px 0;
      padding: 16px 20px;
      background: $color-gray-background;
      @include breakpoint($landscape-up) {
        margin: 0;
      }
    }
    .order-summary {
      &__content {
        padding: 16px 20px;
        background: $color-gray-background;
      }
    }
    .offer-code-panel {
      margin: 10px 0;
      @include breakpoint($landscape-up) {
        margin: 0;
      }
      &__content {
        margin-top: 0;
        @include breakpoint($medium-up) {
          padding: 5px 10px 10px;
        }
      }
      .offer-code {
        &__one-offer-only {
          margin: 18px 0 3px;
        }
      }
      &.expando-block {
        .expando-block__icon {
          @include icon('plus', 'after', false, false, 20px);
          @include breakpoint($medium-up) {
            display: none;
          }
        }
        &.expando-block--expanded {
          .expando-block__icon {
            @include icon('minus', 'after', false, false, 20px);
            @include breakpoint($medium-up) {
              display: none;
            }
          }
        }
      }
      .fleft {
        float: $ldirection;
        width: 55%;
      }
      .fright {
        float: $rdirection;
        width: 40%;
        input[type='submit'] {
          line-height: 3.144;
          height: 44px;
          margin-top: 0;
        }
      }
    }
    .links-panel {
      .link {
        display: block;
        border: none;
        .overlay-link {
          border-bottom: 1px solid $color-pink;
          text-decoration: none;
        }
      }
    }
    &.checkout_signin {
      .customer-service-header {
        @include swap_direction(padding, 0 1em 1em);
      }
    }
    .product-seperation {
      background: transparent;
    }
  }
  &__return-user,
  &__new-account {
    &__toggle {
      margin-top: 30px;
      font-weight: bold;
      text-transform: uppercase;
      .link {
        width: 100%;
        text-align: center;
      }
      &--txt {
        margin-bottom: 20px;
      }
    }
  }
  .forgot-pw-note-text {
    padding: 0;
    line-height: 15px;
    border: 0;
    border-bottom: 1px solid $color-light-gray;
    display: inline-block;
    width: auto;
    margin-top: 10px;
  }
  .confirm & {
    .confirmation-panel {
      &__actions {
        @include breakpoint($landscape-up) {
          margin-bottom: 20px;
        }
      }
    }
    .registration-panel {
      padding-bottom: 0;
      .create-account {
        @include breakpoint($landscape-up) {
          width: 43%;
        }
      }
    }
    &#confirmation-page {
      &.checkout-confirmation-page {
        padding-top: 60px;
      }
    }
  }
  .payment-panel {
    .non-installment-options,
    .installment-options,
    .linepay-options {
      border: 1px solid $color-light-gray;
      margin-bottom: 10px;
      .installment-options-radios,
      .non-installment-options-radios {
        margin-bottom: 0.8em;
        padding-#{$ldirection}: 10px;
      }
    }
    .nccc-media {
      @include swap_direction(padding, 10px 20px 0 0);
      float: #{$rdirection};
      img {
        height: 25px;
        width: 35px;
      }
    }
    .linepay-options-header {
      padding: 10px;
    }
    .header-section {
      display: inline-block;
      width: 100%;
      margin-#{$ldirection}: 10px;
      .installment-options-header,
      .non-installment-options-header {
        padding: 10px;
        float: #{$ldirection};
      }
    }
  }
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
  }
  .back-to-cart {
    text-align: center;
    @include breakpoint($medium-up) {
      float: $ldirection;
    }
    .link-text {
      color: $color-gray-link;
    }
  }
}

.cart-items {
  .cart-header {
    .total {
      width: 16%;
    }
    .price {
      width: 18%;
    }
  }
  .remove_link {
    font-size: 25px;
    border: 0;
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    @include breakpoint($medium-up) {
      position: static;
      font-size: 14px;
    }
  }
  .price {
    .discount {
      line-height: 1.5;
    }
  }
  .qty {
    margin-top: 0;
  }
  .remove {
    &__content {
      display: none;
      @include breakpoint($medium-up) {
        display: inline-block;
        border-bottom: 2px solid $color-pink;
      }
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: 70px;
      }
    }
  }
}

.index {
  .checkout {
    &__content {
      margin: 0 20px;
      @include breakpoint($medium-up) {
        padding: 0 20px;
        margin: 0;
      }
    }
    &__sidebar {
      padding: 0 20px;
    }
  }
  .checkout-progress {
    margin: 25px 0;
    &__shipping,
    &__review,
    &__confirm {
      display: inline;
      width: 100%;
      @include breakpoint($medium-up) {
        margin-#{$rdirection}: 10%;
      }
    }
  }
  .shipping-panel {
    &.adpl {
      .form-item {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 1em 1.3em 0);
          width: 50%;
          float: #{$ldirection};
          margin-bottom: 0;
        }
      }
      .deliver-or-pickup {
        margin: 10px 0;
        display: inline-block;
        &--select {
          @include swap_direction(margin, 15px 15px 15px 0);
        }
      }
      .address-form-container {
        .billing-selection {
          margin: 10px 0;
        }
      }
      .invoice-method-options {
        label {
          margin: 10px 0;
        }
      }
      .invoice-address {
        &--check {
          margin-top: 5px;
        }
      }
      .gift-message-section {
        margin-top: 10px;
        .form-item {
          &.card-message-to {
            width: 49%;
            margin-#{$rdirection}: 2%;
            float: #{$ldirection};
          }
          &.card-message-from {
            width: 49%;
            float: #{$ldirection};
          }
          &.card-message {
            width: 100%;
            textarea {
              width: 48%;
              &.error {
                border: 1px solid $color-red;
              }
            }
          }
        }
      }
      .pickup-label {
        @include swap_direction(padding, 10px 0 5px 10px);
        float: #{$ldirection};
      }
      .pickup-store {
        padding: 5px 10px;
        float: #{$ldirection};
      }
      .shipping-title {
        text-align: center;
        font-size: 21px;
      }
    }
    .trans-detail-container {
      .checkout {
        &__panel-title {
          border-top: 1px solid $color-gray-border;
        }
      }
      .billing-address,
      .gift-info,
      .delivery-opt,
      .invoice-opt,
      .order-type,
      .delivery-add {
        padding: 1em;
      }
    }
    .select-payment {
      padding-bottom: 10px;
    }
    .delivery_method_default {
      padding: 10px 0;
    }
  }
  &.shipping {
    .content-breadcrumb {
      .checkout-progress {
        &__shipping {
          @include progressbar-font;
        }
        .shipping {
          &:before {
            @include progressbar-color;
          }
        }
      }
    }
    .add-button {
      @include breakpoint($medium-up) {
        float: $rdirection;
      }
    }
  }
  &.review {
    .content-breadcrumb {
      .checkout-progress {
        &__review {
          @include progressbar-font;
        }
        .review {
          &:before {
            @include progressbar-color;
          }
        }
      }
    }
    .continue-button-wrapper {
      @include breakpoint($medium-up) {
        float: $rdirection;
      }
    }
  }
  &.confirm {
    .content-breadcrumb {
      .checkout-progress {
        &__confirm {
          @include progressbar-font;
        }
        .confirm {
          &:before {
            @include progressbar-color;
          }
        }
      }
    }
  }
}

.device-mobile {
  .checkout {
    &__return-user,
    &__new-account {
      padding-bottom: 0;
    }
  }
}

.invoice-options-container-electronic {
  .invoice-img {
    width: auto;
  }
}

.samples-page {
  #samples-panel {
    .product {
      .details {
        min-height: 100px;
      }
      .sample-select-button {
        &.selected {
          background-color: $color-pink;
          &.active {
            background-color: $color-pink;
            &:hover {
              background-color: $color-cta-hover;
            }
          }
        }
      }
    }
  }
  .samples-grid {
    &--item {
      @include breakpoint($medium-up) {
        height: 500px;
      }
    }
  }
}

.mobile-breadcrumb {
  display: block;
  margin: 10px 0px;
  .checkout-progress {
    li {
      text-transform: uppercase;
      width: 33.33%;
      float: #{$ldirection};
      position: relative;
      text-align: center;
      font-size: 12px;
      color: $color-gray-dark;
      &:before {
        @include progressbar-before;
      }
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: $color-gray-border;
        position: absolute;
        #{$ldirection}: -50%;
        top: 7px;
        z-index: 1;
      }
      &:first-child:after {
        content: none;
      }
    }
  }
}

.active_panel {
  &--payment {
    .checkout {
      &__sidebar {
        @include breakpoint($medium-up) {
          width: 100%;
          padding: 0 20px;
        }
        @include breakpoint($landscape-up) {
          width: $checkout-sidebar-width;
        }
      }
      &__content {
        @include breakpoint($medium-up) {
          width: 100%;
          padding: 0 20px;
        }
        @include breakpoint($landscape-up) {
          width: 100% - $checkout-sidebar-width;
        }
        iframe {
          @include breakpoint($medium-up) {
            width: 100%;
            height: 400px;
          }
          @include breakpoint($landscape-up) {
            width: 800px;
            height: 600px;
          }
        }
      }
    }
  }
}
.mobile-checkout-back {
  .back-button {
    &__text {
      display: inline;
      font-size: 21px;
      vertical-align: top;
    }
  }
}
.viewcart {
  .icon-arrow {
    &_left:before {
      content: '';
    }
  }
}

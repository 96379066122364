/* prechat overlay */
.prechat-form-container {
  width: 500px;
  /* height:353px; */
  overflow: hidden;
  background-color: white;
  overflow: hidden;
  @include swap_direction(padding, 4px 12px);
}

.prechat-form-container .prechat-x-close {
  background: url('/images/btns/x-close.png') no-repeat scroll 100% 60% transparent;
  clear: both;
  float: $rdirection;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-#{$rdirection}: -10px;
}

.prechat-form-container .product-question-btn {
  color: white;
  float: $ldirection;
  text-align: center;
  cursor: pointer;
  height: 42px;
  margin-top: 5px;
}

.prechat-form-container #beauty-advice-btn {
  float: $ldirection;
}

.prechat-form-container #order-question-btn {
  float: $rdirection;
}

.prechat-form-container .require-msg {
  padding-bottom: 9px;
  font-size: 11px;
  text-transform: none;
}

.prechat-form-container .instruction-msg {
  font-size: 12px;
  letter-spacing: 0.2em;
  font-weight: bold;
  padding-bottom: 1px;
}

.prechat-form-container .registered-input {
  padding-bottom: 12px;
  font-size: 12px;
}

.prechat-form-container .registered-input span {
  letter-spacing: 0.1em;
  text-transform: lowercase;
}

.prechat-form-container .form-row-msg p {
  font-size: 0.8em;
}

.prechat-form-container h4 {
  text-align: $ldirection;
  margin-top: 2px;
}

.prechat-form-container h2 {
  text-align: left;
  margin-top: 2px;
  font-size: 20px;
  margin-bottom: 0px;
}

.prechat-form-container .form-row {
  clear: both;
}

.prechat-form-container .form-row .form-col-one,
.prechat-form-container .form-row .form-col-two {
  width: 230px;
  float: $ldirection;
}

.prechat-form-container .textarea-form-row {
  @include swap_direction(padding, 12px 0 6px 0);
}

.prechat-form-container .textarea-top-msg {
  clear: both;
  padding-top: 16px;
}

.prechat-form-container textarea {
  width: 475px;
  box-sizing: border-box;
}

#prechat_container .textarea-top-msg {
  margin-top: 12px;
}

#prechat_container .textarea-top-msg,
.prechat-form-container label {
  display: block;
  font-family: arial;
  font-size: 11px;
  letter-spacing: 0.2em;
  margin-bottom: 1px;
  color: black;
}

.prechat-form-container .prechat-input {
  width: 220px;
}

.prechat-form-container .prepopulated-input {
  margin-top: 6px;
}

.prechat-form-container .form-col-one {
  float: $ldirection;
}

.prechat-form-container .form-col-two {
  float: $ldirection;
  margin-#{$ldirection}: 10px;
}

.prechat-form-container .form-col {
  width: 49%;
}

.prechat-form-container .form-row-btn {
  @include swap_direction(margin, 10px auto);
  text-align: center;
}

.prechat-form-container .form-spacer {
  float: $ldirection;
  @include swap_direction(margin, 20px);
}

.prechat-form-container .form-row-msg {
  clear: both;
}

.prechat-form-container .form-row-btn {
  @include swap_direction(margin, 0 auto);
  width: 150px;
  background-color: #0d091a;
  display: inline-block;
  text-align: center;
}

.prechat-form-container .form-row-btn div {
  float: $ldirection;
}

.prechat-form-container .form-row-btns {
  width: 480px;
  @include swap_direction(margin, 10px auto);
  cursor: pointer;
}

.prechat-form-container .form-row-btn span {
  color: white;
}

.prechat-form-container #textarea-your-question {
  font-size: 11px;
}

.prechat-form-container .lpbuttonproduct-msg,
.prechat-form-container .footarea .prechat-messaging-area {
  color: black;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.3em;
  padding-top: 0;
  text-transform: none;
}
/* lpbuttonproduct */
.product-question-btn .chat_link_left_text_container {
}

.product-question-btn .chat_link_left_text {
  color: white;
  font-size: 16px;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}
/* styles used to override globals as inline prechat css */
h4.prechat-heading {
  text-align: $ldirection;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: arial;
  font-weight: normal;
  margin-top: 5px;
}

.prechat-form-container {
  text-align: $ldirection;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: arial;
}

.lpbuttonproduct-msg {
  clear: both;
  @include swap_direction(padding, 6px 0px);
  font-size: 10px;
}

.prechat-form-container .not-you-link {
  font-size: 11px;
}

.prechat-messaging-area a {
  border-bottom: 1px solid #e40e62;
  padding-bottom: 1px;
  text-decoration: none;
}

.prechat-messaging-area p {
  @include swap_direction(margin, 0px);
  padding-bottom: 16px;
}

.prechat-messaging-area {
  clear: both;
  font-size: 11px;
  letter-spacing: 0.2em;
  @include swap_direction(padding, 0);
  text-transform: none;
  color: black;
}

.prechat-messaging-area-top {
  margin-bottom: 20px;
  text-transform: none;
  font-size: 11px;
}

.prechat-messaging-area-top .required {
  letter-spacing: 0.2em;
  @include swap_direction(padding, 3px 0);
  color: black;
}

.prechat-msg-emailtranscript-send {
  color: black;
  font-size: 0.5em;
  letter-spacing: 0.3em;
  margin-top: 12px;
  text-transform: none;
}
/* sticky-chat footer area */
.sticky-chat .prechat-messaging-area a {
  color: #e40e62;
}

.sticky-chat .prechat-form-container .registered-input span {
  color: black;
}

.sticky-chat .prechat-form-container #input-email-address,
.sticky-chat .prechat-form-container #input-first-name {
  height: 26px;
}

.sticky-chat .prechat-form-container .instruction-msg {
  font-size: 0.5em;
  margin-top: -4px;
  color: black;
}

.sticky-chat .sticky-chat-content {
  margin-top: 6px;
  margin-#{$ldirection}: -17px;
}

.sticky-chat .prechat-form-container .form-col-two {
  margin-top: 18px;
}

.sticky-chat .prechat-messaging-area p {
  padding-bottom: 11px;
}

.sticky-chat .prechat-form-container {
  border: 1px solid lightgray;
  width: 240px;
  height: 434px;
  background-color: white;
}

.sticky-chat .prechat-form-container h4 {
  font-size: 0.6em;
  letter-spacing: 0.2em;
  display: none;
}

.sticky-chat .prechat-x-close {
  visibility: hidden;
  background: url('/media/images/customer_service/vba/vba-x-close.png') no-repeat scroll 100% 60% transparent;
}

.sticky-chat .prechat-form-container .require-msg {
  letter-spacing: 0.1em;
  font-size: 11px;
  color: gray;
  @include swap_direction(margin, 1px 0 3px 0);
}

.sticky-chat .prechat-form-container #textarea-your-question,
.sticky-chat .prechat-form-container #input-email-address,
.sticky-chat .prechat-form-container #input-first-name {
  width: 210px;
}

.sticky-chat .prechat-form-container #textarea-your-question {
  margin-top: 14px;
  height: 60px;
  resize: none;
  font-size: 0.7em;
}

.sticky-chat .prechat-form-container .textarea-top-msg,
.sticky-chat .prechat-form-container .form-col label {
  display: none;
}

.sticky-chat .prechat-form-container .form-col-two {
  margin-#{$ldirection}: 0px;
  margin-top: 10px;
}

.sticky-chat .prechat-form-container .lpbuttonproduct-msg {
  font-size: 0.5em;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-bottom: 6px;
}

.sticky-chat .prechat-form-container .prechat-messaging-area {
  margin-top: 12px;
  font-size: 10px;
  letter-spacing: 0.1em;
}

.sticky-chat .sticky-chat__copy {
  height: 42px;
}

.sticky-chat .prechat-form-container .prechat-input {
  @include swap_direction(margin, 3px 0);
}

.sticky-chat .prechat-form-container .product-question-btn {
  @include swap_direction(margin, 6px 0);
}

.sticky-chat .integrated-prechat-settled {
  height: 80px;
  overflow: hidden;
}

.sticky-chat .integrated-prechat {
  margin-top: -38px;
}

.sticky-chat .order-question-btn {
  display: none;
}

.integrated-prechat-container {
  height: 480px;
  /* height: auto; */
}

.order-question-btn {
  display: block;
}

.optanon-show-settings-popup-wrapper {
  display: inline-block !important;
  .optanon-show-settings-button {
    display: block;
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      text-decoration: underline;
      float: none;
      padding-top: 0;
      display: inline-block;
      .optanon-show-settings {
        font-weight: normal;
        color: $color-pink !important;
        font-family: $body-font;
        font-size: 14px;
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $color-pink !important;
    font-size: 14px !important;
    text-decoration: underline;
    letter-spacing: 0;
    text-transform: lowercase;
    line-height: 1;
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(100% - 190px);
        }
        #pc-title {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    .button-group-parent {
      button {
        &:hover {
          background: $color-black !important;
          opacity: 0.7;
        }
      }
    }
    #vendors-list {
      button {
        &:hover {
          background: $color-black !important;
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-group-container {
        width: 100%;
        #onetrust-policy {
          #onetrust-policy-text {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
      #onetrust-button-group-parent {
        width: 95%;
        text-align: #{$rdirection};
        #onetrust-button-group {
          button {
            background: transparent;
            border: none;
            text-decoration: underline;
            width: auto;
            opacity: 1;
          }
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          bottom: 15px;
          #{$ldirection}: 82%;
          top: auto;
          @include breakpoint($medium-up) {
            #{$ldirection}: 96%;
          }
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none !important;
      }
    }
    &.otFloatingRoundedCorner {
      @include swap_direction(padding, 25px 24px 25px 22px);
      width: 100%;
      bottom: 0 !important;
      #{$rdirection}: 0;
      border-radius: 0;
      @include breakpoint($medium-up) {
        width: 295px;
        #{$rdirection}: 16px;
        bottom: 10px !important;
      }
      p {
        display: inline !important;
        font-size: 14px;
      }
      #onetrust-group-container {
        margin-#{$ldirection}: 0;
        float: none;
        #onetrust-policy {
          margin-top: 0;
          .banner-header {
            margin: 0;
          }
          #onetrust-policy-text {
            padding: 0;
            line-height: normal;
            float: none;
            color: $color-black;
          }
          #onetrust-close-btn-container {
            margin-#{$rdirection}: -5%;
            margin-top: -5%;
          }
        }
      }
      #onetrust-button-group-parent {
        padding: 0;
        float: none;
        #onetrust-pc-btn-handler {
          padding: 0;
          margin: 0;
          width: auto;
          letter-spacing: normal;
          font-size: 14px;
          text-decoration: none;
          color: $color-pink !important;
        }
      }
    }
    #onetrust-button-group {
      display: inline;
    }
  }
}

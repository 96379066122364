$color-tamarillo: #a81010;

@mixin bg-position($x, $y) {
  @if $text-direction == ltr {
    background-position: $x $y;
  } @else {
    background-position: 100% - $x $y;
  }
}

.info-link {
  text-align: left;
}

.appt-book {
  #appointment-book-sections {
    .appointment-booking-location-select {
      .submit_geoloc__submit {
        width: calc(97% - 79px);
      }
    }
    label.error {
      color: $color-red;
    }
    .appointment-booking-review__account-signup-gdpr-compliant {
      .gdpr_compliant__info-right-icon {
        margin-left: 10px;
        overflow: hidden;
        width: 30px;
        height: 30px;
      }
      .gdpr_compliant__info-for-left,
      .gdpr_compliant__info-right-icon {
        float: left;
      }
    }
    .appointment-booking-review__account-signup-form {
      clear: left;
      .signup__mobile-prefix {
        display: none;
      }
      .signup__mobile-phone {
        padding-left: 10px !important;
      }
    }
    .appointment-booking-review__error-messages .error-message,
    .appointment-booking-review__error-messages .error-message a {
      color: $color-tamarillo;
      margin: 0.5em 0;
      font-weight: bold;
    }
    .date-time .date-time__hour,
    #confirmation .appt-time {
      display: inline;
      word-spacing: -3px;
    }
    #confirmation {
      .appt-date {
        display: inline;
      }
    }
    #services {
      .services-container {
        .service-title {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}

.touchevents {
  .appt-book {
    .location-form {
      &__select {
        @include bg-position(97%, 46%);
      }
    }
  }
}
